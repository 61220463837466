.player-wrapper {
    position: relative;
    width: 100%;
    padding-top: 56.25%; /* 16:9 Aspect Ratio */
    overflow: hidden; /* Ensure the logo stays within bounds */
  }
  
  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }
  
  .player-logo {
    position: absolute;
    top: 10px;
    left: 10px;
    width: 50px; /* Adjust size as needed */
    z-index: 10; /* Ensure the logo is above the player */
  }
  